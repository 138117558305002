.radiogroup input[type="radio"] {
  display: none;
}

.radiogroup label {
  display: inline-block;
  background-color: white;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px 5px 10px 5px;
  text-align: center;
  border-radius: 50px;
  border-style: solid;
  border-color: #cccccc;
  border-width: 1px;
}

.radiogroup input[type="radio"]:checked + label {
  background-color: #bbb;
}

.radiogroup label:hover {
  background-color: #ffc9c9;
  cursor: pointer;
  color: white;
}

.mobile input[type="radio"] {
  display: none;
}

.mobile label {
  display: inline-block;
  background-color: white;
  font-family: Arial;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
  padding: 10px 5px 10px 5px;
  text-align: center;
  border-radius: 50px;
  border-style: solid;
  border-color: #cccccc;
  border-width: 1px;
}

.mobile input[type="radio"]:checked + label {
  background-color: #bbb;
}

/* .radiogroup label:hover {
  background-color: #ffc9c9;
  cursor: pointer;
  color: white;
} */
