html {
  margin: 0;
  padding: 0;
  height: 100%;
}

html.questionnaire {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.ui.progress .bar {
  background: #fff777;
  height: 15px;
  border-radius: 50px;
}

.ui.progress {
  background: #ffc9c9;
  height: 15px;
  border-radius: 50px;
}

footer {
  height: auto;
  min-height: 50px;
  flex-shrink: 0;
  background-color: #424242;
  /* margin-top: 30px; */
  width: 100%;
  color: white;
  font-size: 14pt;
  text-align: center;
  padding-top: 18px;
}
